import { Component, Inject, OnInit } from "@angular/core";
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogModule,
} from "@angular/material/legacy-dialog";
import { SafeHtml } from "@angular/platform-browser";
import { ListService } from '../list.service';

@Component({
  selector: "app-release-popup",
  templateUrl: "./release-popup.component.html",
  styleUrl: "./release-popup.component.scss",
})
export class ReleasePopupComponent implements OnInit{
  releaseBannerDetails: SafeHtml;

  constructor(
    private dialogRef: MatDialogRef<ReleasePopupComponent>,
    private listService: ListService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.releaseBannerDetails = data?.releaseBannerDetails;
  }

  ngOnInit() {}

  closePopup = () => this.dialogRef.close();
}
