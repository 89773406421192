import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { BaseService } from "@projects/shared-lib/src/lib/services/base-http.service";
import { UserInfo } from 'src/app/shared/constants/ascend-user-project-info';
import { environment } from "src/environments/environment";
import { PROJECT_STATUS } from "@projects/shared-lib/src/lib/utility/constants";

interface Data {
  projectId: number;
  pinnedStatus: boolean;
}

interface PinnedApiResponse {
  status: number;
  data: Data;
  timestamp: string;
}

@Injectable({
  providedIn: "root",
})
export class ListService {

  BASE_URL='';
  public isExpiredProject :any= new Subject();
  constructor(private http : HttpClient,
    private baseService: BaseService) {
      this.BASE_URL=environment.API_MICROSERVICE_URL.PROJECT;
  }

  getUserProjectInfo(params, selectedIndexTab = 0, BASE_URL=this.BASE_URL) {
    if(selectedIndexTab == 3){
      params = params.append("project_status",PROJECT_STATUS.EXPIRED);
      return this.getExpiredProjectInfo(params, BASE_URL);
    }
    if(selectedIndexTab == 0){
      params = params.append("project_status", PROJECT_STATUS.APPROVED);
    } else if(selectedIndexTab == 1) {
      params = params.append("project_status",PROJECT_STATUS.DRAFT);

    } else if(selectedIndexTab == 2) {
      params = params.append("project_status",PROJECT_STATUS.REJECTED);
    } else if(selectedIndexTab == 4){
      params = params.append("project_status",PROJECT_STATUS.PENDING_APPROVAL); 
    }
    return this.http.get<UserInfo>(BASE_URL+'/project',{params});
  }

  getFilteredUsersProject(payload, selectedIndexTab,params, BASE_URL=this.BASE_URL) {
    if(selectedIndexTab == 3){
      params = params.append("project_status",PROJECT_STATUS.EXPIRED);
      payload.project_status = PROJECT_STATUS.EXPIRED;
      return this.getExpiredProjectInfo(params, BASE_URL);
    }
    if(selectedIndexTab == 0){
      payload.project_status = PROJECT_STATUS.APPROVED;
    } else if(selectedIndexTab == 1) {
      payload.project_status = PROJECT_STATUS.DRAFT;
    } else if(selectedIndexTab == 2) {
      payload.project_status = PROJECT_STATUS.REJECTED;
    } else if(selectedIndexTab == 4){
      payload.project_status = PROJECT_STATUS.PENDING_APPROVAL;
    }
    return this.http.post(BASE_URL +`/project/filterProjects`, payload);
  }

  getPinnedProjects(email: String) {
    return this.http.get(this.BASE_URL+`/project/pinnedProjects?email=${email}`);
  }
  
  getPackagePref(package_id: number,function_id: number,feature_id: number, langId) {
    return this.http.get(environment.API_MICROSERVICE_URL?.ADMIN+`/package/preferences/${package_id}/${function_id}/${feature_id}?languageId=${langId}`);
   }
   getUserPreferences(){
     return this.http.get<any>(`${environment.API_MICROSERVICE_URL?.METHOD}/package/package-detail?statusId=1`).map(res => this.getPckgResponse(res));
   }

   getPckgResponse(res) {
    res?.data?.forEach((item) => {
      item.name = item.package_method_nm;
      item.package_id = item.package_method_id;
      item.project_mode = item.is_project_mode_set ? 'Y' : 'N';
    })
    return res;
   }
 
  // get the project details for the logged in user
  getCurrentUserInfo(userId) {
    return this.http.get(`${environment.API_MICROSERVICE_URL?.USER}/user?email=${userId}`);
  }

  getProjectInfo(userId: any) {
    return this.http.get(
      `${this.BASE_URL}/project?email=${userId}&sortBy=date&sortOrder=desc`
    );

  }
  getIsExpiredProject(): Observable<any> {
    return this.isExpiredProject.asObservable();
  }

  setIsExpiredProject(value) {
    this.isExpiredProject.next(value);
  }

  getExpiredProjectInfo(params, BASE_URL = this.BASE_URL) {
    return this.http.get<UserInfo>(BASE_URL+'/project/expired',{params});
  }

  getProjectDetailsByProjectId(projectId) {
    return this.http.get(this.BASE_URL + `/project/` + projectId);
  }

  updatePinnedProject(payload, BASE_URL = this.BASE_URL): Observable<PinnedApiResponse>{
    return this.http.post<PinnedApiResponse>(BASE_URL + `/project/pinProject/update`, payload);
  }

  getRegionSelection(languageId?): any {
    return this.http.get(environment.API_MICROSERVICE_URL?.PROJECT + `/project/region-selection?languageId=${languageId}`)
  }

  getGlobalInstances(languageId?): any {
    return this.http.get(environment.API_MICROSERVICE_URL?.PROJECT + `/project/global-instances?languageId=${languageId}`)
  }
}